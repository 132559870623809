// Hook that validates and returns the client variables

import { useMatches } from "@remix-run/react";

export const useClientVariables = () => {
  // Get root match
  const match = useMatches()[0] as any;

  return match.data.ENV;
  // Get from window
};
