import { Dialog } from "@headlessui/react";
import { Link } from "@remix-run/react";
import { useState } from "react";
import { FaBars } from "react-icons/fa";

import { cn } from "@wind/ui/util";

import WindmillLogo from "~/components/WindmillLogo.js";
import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";
import { ArrowRightIcon, XIcon } from "~/utils/icons.js";

// This is a placeholder for when we have actual navigation items
const NAV_ITEMS: Array<{
  name: string;
  href: string;
}> = [];

interface Props {
  variant?: "light" | "dark";
}

const Nav = ({ variant = "dark" }: Props) => {
  const user = useOptionalAuthUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between px-4 py-6 lg:px-8" aria-label="Global">
        <Link className="group flex items-center gap-2 text-xl lg:flex-1" to="/" prefetch="render">
          <WindmillLogo variant={variant} />
          <span
            className={cn("font-medium", {
              "text-black": variant === "dark",
              "text-white": variant === "light",
            })}
          >
            Windmill
          </span>
        </Link>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="text-gray-700 -m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FaBars
              className={cn("h-6 w-6", {
                "text-black": variant === "dark",
                "text-white": variant === "light",
              })}
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {NAV_ITEMS.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-sm font-semibold leading-6"
              prefetch="render"
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {user && (
            <Link
              to="/s"
              className={cn(
                "font-medium leading-6 tracking-tight group text-lg transition-colors flex gap-1 items-center",
                {
                  "text-black": variant === "dark",
                  "text-white": variant === "light",
                }
              )}
            >
              <span className="group-hover:underline underline-offset-4">Open Windmill</span>{" "}
              <span aria-hidden="true">
                <ArrowRightIcon />
              </span>
            </Link>
          )}
          {!user && (
            <Link
              to="/auth"
              className={cn(
                "font-medium leading-6 tracking-tight group text-lg transition-colors flex gap-1 items-center",
                {
                  "text-black": variant === "dark",
                  "text-white": variant === "light",
                }
              )}
            >
              <span className="group-hover:underline underline-offset-4">Login</span>{" "}
              <span aria-hidden="true">
                <ArrowRightIcon />
              </span>
            </Link>
          )}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="sm:ring-gray-900/10 fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-base px-6 py-6 sm:max-w-sm sm:ring-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 font-medium">
              <WindmillLogo />
              Windmill
            </div>
            <button
              type="button"
              className="text-gray-700 -m-2.5 rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XIcon />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="divide-gray-500/10 -my-6 divide-y">
              <div className="space-y-2 py-6">
                {NAV_ITEMS.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-gray-900 hover:bg-gray-50 -mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6"></div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Nav;
