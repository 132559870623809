import { cn } from "@wind/ui/util";

interface Props {
  className?: string;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  variant?: "light" | "dark";
}

const WindmillLogo = ({ className, size = "md", variant = "dark" }: Props) => {
  return (
    <div
      className={cn(
        "relative overflow-hidden",
        {
          "h-8 w-8": size === "sm",
          "h-10 w-10": size === "md",
          "h-16 w-16": size === "lg",
          "h-20 w-20": size === "xl",
          "h-24 w-24": size === "2xl",
        },
        className
      )}
    >
      <img
        src={variant === "dark" ? "/logo-black.svg" : "/logo-white.svg"}
        alt="Windmill"
        className={cn("absolute inset-0 hover:animate-spin group-hover:animate-spin", {})}
      />
    </div>
  );
};

export default WindmillLogo;
